const th = {
  translation: {
    client: {
      enhancers: {
        withPreventLeaveDirtyForm: {
          cancelEdit: "ยกเลิกการแก้ไขข้อมูล",
          cancelConfirm: "ข้อมูลปัจจุบันจะไม่ถูกบันทึก และไม่สามารถกู้คืนได้",
          close: "ปิดหน้าต่างนี้",
          canceledEdit: "ยกเลิกการแก้ไข",
        },
      },
      api: {
        modal401Title: "การเข้าสู่ระบบหมดอายุ",
        modal401Children:
          "การเข้าสู่ระบบหมดอายุ กรุณาทำการเข้าสู่ระบบใหม่อีกครั้ง",
        modal401OkButtonLabel: "เข้าสู่ระบบ",
        modal403Title: "เกิดข้อผิดพลาด",
        modal403Children:
          "บทบาทผู้ใช้งานของคุณไม่มีสิทธิ์ใช้งานส่วนนี้ กรุณาเข้าสู่ระบบอีกครั้ง",
        modal403OkButtonLabel: "เข้าสู่ระบบ",
        modal500Title: "เกิดข้อผิดพลาด",
        modal500OkButtonLabel: "ตกลง",
      },
      pages: {
        main: {
          admins: {
            index: {
              title: "ผู้ดูแลระบบ",
              home: "หน้าแรก",
              addButton: "เพิ่มผู้ดูแลระบบ",
              admins: "รายชื่อผู้ดูแลระบบ",
              deleteButton: "ต้องการลบข้อมูลผู้ดูแลระบบ",
              columns: {
                fullName: "ชื่อ นามสกุล",
                email: "อีเมล",
                status: "สถานะ",
                code: "รหัส",
                lastActiveAt: "ใช้งานล่าสุด",
                createdAt: "สร้างเมื่อ",
                updatedAt: "แก้ไขเมื่อ",
                role: "บทบาท",
                values: {
                  availableStatus: {
                    active: "ใช้งาน",
                    inactive: "ไม่ใช้งาน",
                  },
                },
              },
            },
            new: {
              home: "หน้าแรก",
              rootPath: "ผู้ดูแลระบบ",
              title: "เพิ่มผู้ดูแลระบบ",
              header: "กรอกอีเมลผู้ดูแลระบบเพื่อส่งลิงค์เข้าใช้งาน",
              link: "ลิงก์เพื่อเข้าใช้งานระบบจะถูกส่งไปที่อีเมลนี้",
              send: "ส่งเมล",
              cancel: "ยกเลิก",
              email: "อีเมล",
              role: "บทบาท",
              notificationSuccess: "ทำรายการสำเร็จ",
            },
            edit: {
              title: "ข้อมูลทั่วไป",
              home: "หน้าแรก",
              rootPath: "ผู้ดูแลระบบ",
              status: "สถานะ",
              cancel: "ยกเลิก",
              save: "บันทึก",
              cantEditYourself:
                "การแก้ไขสถานะและบทบาทไม่สามารถทำได้ด้วยตัวเอง ต้องให้ Admin ที่มี permission แก้ไข permission ของ Admin ทำให้เท่านั้น เช่น Super Admin",
              firstName: "ชื่อ",
              lastName: "นามสกุล",
              role: "บทบาท",
              email: "อีเมล",
              available: "ใช้งานระบบ",
            },
          },
          authentication: {
            LoginPage: {
              or: "หรือ",
              title: "My Benefit",
              title1: "กรอกอีเมลรับรหัส OTP เพื่อเข้าสู่ระบบ",
              title2: "กรอกอีเมลรับรหัส OTP เพื่อลงทะเบียนใช้งาน",
              emailField: "อีเมล",
              getOtp: "รับรหัส OTP",
              invalidEmail: "รูปแบบอีเมลไม่ถูกต้อง",
              signInWithAzureAd: "เข้าสู่ระบบด้วย Azure AD",
              logoutAzureAd: "ออกจากระบบ Azure AD",
            },
            VerifyOtp: {
              title: "กรอกรหัส OTP เพื่อเข้าสู่ระบบ",
              otpInfo:
                "กรอกรหัส OTP ที่ได้รับทาง {{currentEmail}} (OTP มีอายุการใช้งาน {{otpExpireInMin}} นาที)",
              otp: "OTP",
              refCode: "เลขอ้างอิง:{{refCode}}",
              notReceiveOtp: "ไม่ได้รหัส OTP?",
              retryOtp: "ขอ OTP อีกครั้ง",
              canRetryIn: "ขออีกครั้งใน {{timeLeft}} วินาที",
              login: "เข้าสู่ระบบ",
              changeEmail: "เปลี่ยนอีเมล",
              home: "กลับไปหน้าแรก",
              otpMustNumber: "รูปแบบ otp ไม่ถูกต้อง",
            },
          },
          enrollment: {
            index: {
              title: "การลงทะเบียน",
              status: "สถานะ",
              year: "ปีที่ใช้งาน",
              reset: "รีเซ็ต",
              filter: "กรอง",
              home: "หน้าแรก",
              startDate: "วันที่เริ่มใช้งาน",
              endDate: "วันสิ้นสุดการใช้งาน",
              amount: "จำนวนผู้เข้าร่วม",
              state: {
                name: "สถานะ",
                all: "ทั้งหมด",
                draft: "แบบร่าง",
                published: "เผยแพร่",
                publishing: "กำลังเผยแพร่",
                pending: "รอแก้ไข",
                active: "ใช้งาน",
                closed: "ปิด",
              },
              updatedAt: "วันที่แก้ไขล่าสุด",
              createEnrollment: "สร้างการลงทะเบียน",
              deleteTitle: "ลบข้อมูล",
              deleteModalInfo1: "ข้อมูล",
              enrollment: "การลงทะเบียนปี {{year}}",
              deleteModalInfo2: "จะถูกลบและไม่สามารถกู้คืนได้",
              closeDeleteModal: "ปิดหน้าต่างนี้",
            },
            CreateEnrollmentModal: {
              title: "สร้างรายการลงทะเบียน",
              description:
                "โปรดระบุปีที่ใช้งาน (ปีที่ใช้งานต้องไม้ซ้ำกับที่มีอยู่แล้วในระบบ)",
              year: "ปีที่ใช้งาน",
              cancel: "ยกเลิก",
              error: {
                title: "เกิดข้อผิดพลาด",
                close: "ปิดหน้าต่างนี้",
              },
            },
            detail: {
              title: "การลงทะเบียน {{year}}",
              step: "ขั้นตอน ({{step}}/{{of}})",
              draft: "แบบร่าง",
              pending: "รอแก้ไข",
              published: "เผยแพร่",
              publishing: "กำลังเผยแพร่",
              active: "ใช้งาน",
              closed: "ปิด",
              home: "หน้าแรก",
              enrollment: "การลงทะเบียน",
              state: {
                1: "กำหนดช่วงเวลา",
                2: "ตรวจสอบผู้เข้าร่วม",
                3: "จัดการแบบประกัน",
                4: "จัดการแพ็กเกจประกัน",
                5: "จัดชุดข้อมูล",
                6: "ตรวจสอบและเผยแพร่",
              },
              pendingText:
                "ถ้าพบข้อมูลประกันไม่ถูกต้อง กรุณากดแจ้งแก้ไขแบบประกันและติดต่อ Belive ให้แก้ไขแบบประกันให้ถูกต้องก่อนดำเนินการต่อ",
              pendingButton: "แจ้งแก้ไขแบบประกัน",
              cancel: "ยกเลิก",
              pendingModalText1:
                "ระบบจะล็อคไม่ให้เผยแพร่ Enrollment นี้ได้ จนกว่าจะมีการแก้ไขข้อมูลประกันจาก Belive",
              pendingModalText2:
                "* หลังจากกดแจ้งแก้ไขแล้ว กรุณาระบุรายละเอียดการแก้ไขผ่านช่องทางการติดต่อของ Belive",
            },
            PeriodSetting: {
              enrollmentPeriodSetting: "กำหนดช่วงเวลาของการลงทะเบียน",
              updatedAt: "แก้ไขข้อมูลล่าสุดเมื่อ: {{updatedAt}}",
              startDate: "วันเริ่มต้นที่ให้เลือกประกันได้",
              endDate: "วันสุดท้ายที่ให้เลือกประกันได้",
              existingAttendeeSelectionPeriodInDays:
                "ระยะเวลาที่ให้พนักงานเก่าเลือกได้",
              incomingAttendeeSelectionPeriodInDays:
                "ระยะเวลาที่ให้พนักงานเข้าระหว่างปีเลือกได้",
              existingAttendeeSelectionPeriodInDaysInfo:
                "นับตั้งแต่วันที่เริ่มให้เลือกประกัน",
              incomingAttendeeSelectionPeriodInDaysInfo:
                "นับตั้งแต่วันที่มีสถานะพนักงาน",
              saveAndNext: "บันทึกและถัดไป",
              save: "บันทึก",
              saveSuccess: "บันทึกสำเร็จ",
            },
            enrollmentAttendee: {
              title: "ตรวจสอบผู้เข้าร่วม",
              field: "รหัสพนักงาน / ชื่อ-นามสกุล",
              reset: "รีเซ็ต",
              filter: "กรอง",
              next: "ถัดไป",
              lastUpdate: "อัพเดทข้อมูลล่าสุดเมื่อ: {{updatedAt}}",
              updateData: "อัพเดทข้อมูล",
              code: "รหัสพนักงาน",
              name: "ชื่อ - นามสกุล",
              department: "แผนก",
              position: "ตำแหน่ง",
              filterNotFound:
                "ไม่พบข้อมูลที่ค้นหา กรุณาตรวจสอบค่าในตัวกรองอีกครั้ง",
              syncModal:
                "ระบบกำลังอัพเดทข้อมูล กรุณาอย่าปิดหน้าจอนี้จนกว่าจะอัพเดทข้อมูลเสร็จ",
              noAttendee: "ไม่พบข้อมูลผู้เข้าร่วม",
              syncSuccess: "ทำรายการสำเร็จ",
              syncFail: "ทำรายการไม่สำเร็จ",
            },
            enrollmentInsurancePackage: {
              title: "จัดการแพ็กเกจประกัน",
              name: "ชื่อแพ็กเกจ",
              coverageLevel: "ระดับความคุ้มครอง",
              insuranceName: "ประกัน {{insuranceName}}",
              remark: "หมายเหตุ",
              next: "ถัดไป",
              lastUpdate: "อัพเดทข้อมูลล่าสุดเมื่อ: {{updatedAt}}",
              insuranceFor: {
                employee: "ข้อมูลแผนประกันของพนักงาน",
                family: "ข้อมูลแผนประกันของครอบครัว",
              },
            },
            attendeeGrouping: {
              file: "แนบไฟล์",
              detail: "รายละเอียดแบบประกันสำหรับแสดงใน App ",
              deletetitle: "ลบข้อมูล",
              deletetitle1:
                "ข้อมูล รายละเอียดแบบประกันสำหรับแสดงใน App  จะถูกลบและไม่สามารถกู้คืนได้",
              cancel: "ปิดหน้าต่างนี้",
              title: "จัดชุดข้อมูล",
              info: "*คะแนนที่ใส่ในช่องนี้คิดรวมมูลค่าของประกันเริ่มต้นไว้ด้วย",
              currentScore: "คะแนนที่มีสำหรับรอบนี้",
              unit: "คะแนน",
              type: "วิธีการเลือกแบบประกัน",
              saveAndNext: "บันทึกและถัดไป",
              save: "บันทึก",
              saveSuccess: "บันทึกสำเร็จ",
              ok: "ตกลง",
              titleError: "ไม่สามารถบันทึกข้อมูลได้",
              errorDetail1:
                "มูลค่ารวมของประกันเริ่มต้นในแต่ละกลุ่มพนักงานต้องไม่เกินคะแนนที่พนักงานได้รับ",
              errorDetail2:
                "กรุณาแก้ไข “คะแนนที่มีสำหรับรอบนี้” หรือเลือกประกันเริ่มต้นของกลุ่มพนักงานต่อไปนี้ใหม่",
              custom: "ให้เลือกเอง",
              package: "ให้เลือกจาก package ประกัน",
              none: "ไม่เปิดให้เลือก",
              InsuranceList: {
                add: "เลือกประกัน",
                deleteInsuranceModalTitle: "ลบแบบประกัน",
                deleteInsuranceModalInfo1: "ข้อมูลแบบประกัน",
                deletePackageModalTitle: "ลบ Package",
                deletePackageModalInfo1: "ข้อมูล Package",
                deleteModalInfo2: "จะถูกลบและไม่สามารถกู้คืนได้",
                close: "ปิดหน้าต่างนี้",
                confirmDelete: "ลบข้อมูล",
                insuranceName: "ชื่อแบบประกัน",
                packageName: "ชื่อ Package",
                remark: "หมายเหตุ",
                deleteSuccess: "ทำรายการสำเร็จ",
                modalInsuranceTitle: "เลือกแบบประกัน {{type}} ให้พนักงานประจำ",
                modalPackageTitle: "เลือก Package ให้พนักงานประจำ",
                modalCancel: "ยกเลิก",
                modalConfirm: "เลือก",
                delete: "ลบ",
                insuranceTypeHeader: "แบบประกัน {{type}} ที่เลือกได้",
                packageHeader: "Package ประกันที่เลือกได้",
                default: "ค่าเริ่มต้น",
              },
              InsuranceSelectorModal: {
                insuranceName: "ชื่อแบบประกัน",
                packageName: "ชื่อ Package",
                remark: "หมายเหตุ",
              },
            },
            validate_and_published: {
              index: {
                insuraceDetail: "รายละเอียดแบบประกัน",
                title: "ตรวจสอบและเผยแพร่",
                period: "ช่วงเวลา",
                edit: "แก้ไข",
                warningMessage:
                  "เมื่อเผยแพร่แล้วจะไม่สามารถแก้ไขข้อมูลการลงทะเบียนรอบนี้ได้อีก (นอกจากการเพิ่มระยะเวลาใช้งาน) กรุณาตรวจสอบข้อมูลให้ครบถ้วนก่อนเผยแพร่การลงทะเบียน",
                year: "ประจำปี",
                startDate: "วันเริ่มต้นใช้งาน",
                endDate: "วันสิ้นสุดการใช้งาน",
                existingEnrollmentDaysLeft:
                  "ระยะเวลาเลือกประกันสำหรับพนักงานประจำ",
                incomingEnrollmentDaysLeft:
                  "ระยะเวลาเลือกประกันสำหรับพนักงานใหม่",
                days: "วัน",
                insuranceDetails: "รายละเอียดการเลือกประกัน",
                score: "คะแนน",
                type: "วิธีการเลือกแบบประกัน",
                published: "เผยแพร่",
                publishing: "กำลังเผยแพร่",
                preview: "Preview",
                employees: "พนักงาน",
                code: "รหัสพนักงาน",
                name: "ชื่อ - นามสกุล",
                department: "แผนก",
                position: "ตำแหน่ง",
                insuranceName: "ชื่อแบบประกัน",
                remark: "หมายเหตุ",
                default: "ค่าเริ่มต้น",
                employeeDetail: "รายละเอียดพนักงาน",
                customTitle: "แบบประกัน {{nameTitle}} ที่เลือกได้",
                packageTitle: "Package {{nameTitle}}",
                publishEnrollment: "เผยแพร่การลงทะเบียน",
                cancel: "ยกเลิก",
                publishModalText:
                  "เมื่อเผยแพร่แล้วจะไม่สามารถแก้ไขข้อมูลการลงทะเบียนรอบนี้ได้อีก",
                publishModalText2:
                  "กรุณาตรวจสอบข้อมูลให้ครบถ้วนก่อนเผยแพร่การลงทะเบียน",
                errorTitle: "เกิดข้อผิดพลาด",
                submit: "ตกลง",
                errorModalText: "มีการแก้ไขชุดข้อมูลประกันของ Enrollment นี้",
                errorModalText2:
                  "กรุณารอจนการแก้ไขชุดแบบประกันเสร็จสมบูรณ์ จากนั้นทำรายการอีกครั้ง",
                success: "ทำรายการสำเร็จ",
                errorValidateText:
                  "ไม่สามารถเผยแพร่ได้ เนื่องจากยังกรอกรายละเอียดของกลุ่มพนักงาน {{title}} ไม่ครบถ้วน",
                custom: "ให้เลือกเอง",
                package: "ให้เลือกจาก package ประกัน",
                none: "ไม่เปิดให้เลือก",
                syncEmployeeErrorOnPublishedTitle: "ไม่สามารถเผยแพร่ได้",
                syncEmployeeErrorOnPublished:
                  "ไม่สามารถดึงข้อมูลพนักงานล่าสุดได้ ทำให้ไม่สามารถเผยแพร่ได้ กรุณาตรวจสอบไฟล์ข้อมูลพนักงาน หรือติดต่อผู้ดูแลระบบ",
                publishingModal:
                  "ระบบกำลังเผยแพร่ข้อมูลการลงทะเบียน กรุณาอย่าปิดหน้าจอนี้จนกว่าจะอัพเดทข้อมูลเสร็จ",
              },
            },
            InsurancePlanValidating: {
              title: "จัดการแบบประกัน",
              name: "ชื่อแบบประกัน",
              remark: "หมายเหตุ",
              next: "ถัดไป",
              updatedAt: "อัพเดทข้อมูลล่าสุดเมื่อ: {{date}}",
              coverageLevel: "ระดับ",
              insuranceFor: {
                employee: "ข้อมูลแบบประกันของพนักงาน",
                family: "ข้อมูลแบบประกันของครอบครัว",
              },
            },
            enrollmentInsurance: {
              home: "หน้าแรก",
              enrollment: "การลงทะเบียน",
              name: "ชื่อแบบประกัน",
              insuranceType: "ประเภท",
              protectionStartDate: "วันเริ่มต้นคุ้มครอง",
              protectionEndDate: "วันสิ้นสุดคุ้มครอง",
              premium: "เบี้ยประกัน (บาท)",
              remark: "หมายเหตุ",
              description: "รายละเอียด",
              back: "ย้อนกลับ",
              isFamily: "ประกันสำหรับ",
              coverageLevel: "ระดับคววามคุ้มครอง",
              insuranceFor: {
                employee: "พนักงาน",
                family: "ครอบครัว",
              },
            },
          },
          insurance: {
            detail: {
              title: "ชุดแบบประกันปี {{year}}",
              step: "ขั้นตอน ({{step}}/{{of}})",
              draft: "แบบร่าง",
              published: "เผยแพร่",
              publishing: "กำลังเผยแพร่",
              editing: "แก้ไข",
              insuranceDetailTitle: "ข้อมูลแบบประกัน",
              packageTitle: "จัดแพ็กเกจประกัน",
              validateAndPublish: "ตรวจสอบและเผยแพร่",
              breadcrumbs: {
                home: "หน้าแรก",
                insurance: "จัดการชุดแบบประกัน",
              },
            },
            InsuranceDetail: {
              companyName: "บริษัทประกัน",
              duration: "ช่วงเวลาคุ้มครอง",
              protectionStartDate: "วันเริ่มต้นคุ้มครอง",
              protectionEndDate: "วันสิ้นสุดความคุ้มครอง",
              insuranceData: "ข้อมูลแบบประกัน",
              saveAndNext: "บันทึกและถัดไป",
              save: "บันทึก",
              saveSuccess: "บันทึกสำเร็จ",
              add: "เพิ่มแบบประกัน",
              insuranceFor: {
                employee: "ข้อมูลแบบประกันของพนักงาน",
                family: "ข้อมูลแบบประกันของครอบครัว",
              },
            },
            InsuranceList: {
              add: "เพิ่มแบบประกัน",
              deleteModalTitle: "ลบแบบประกัน",
              deleteModalInfo1: "ข้อมูลแบบประกัน",
              deleteModalInfo2: "จะถูกลบและไม่สามารถกู้คืนได้",
              close: "ปิดหน้าต่างนี้",
              confirmDelete: "ลบข้อมูล",
              name: "ชื่อแบบประกัน",
              premium: "เบี้ยเต็ม",
              remark: "หมายเหตุ",
              deleteSuccess: "ทำรายการสำเร็จ",
              cannotDeleteWhenUsedInPackage:
                "ไม่สามารถลบได้เนื่องจากถูกใช้ใน Packages",
              cannotDeleteWhenHasBeenGroup:
                "ไม่สามารถลบได้เนื้องจากถูกนำไปจัดชุดข้อมูลในการลงทะเบียนปี {{year}}",
              saveYourPackagesFirst: "โปรดบันทึกชุดแผนประกันก่อน",
              coverageLevel: "ระดับ",
              duplicateModalTitle: "คัดลอกแบบประกัน",
              duplicateModalContent: "ต้องการคัดลอกแบบประกัน",
            },
            InsuranceForm: {
              name: "ชื่อแบบประกัน",
              info: "รายละเอียดแบบประกัน",
              nameTh: "ชื่อแบบประกัน (TH)",
              nameEn: "ชื่อแบบประกัน (EN)",
              premium: "เบี้ยประกัน (บาท)",
              remark: "หมายเหตุ",
              remarkTh: "หมายเหตุ (TH)",
              remarkEn: "หมายเหตุ (EN)",
              save: "บันทึก",
              cancel: "ยกเลิก",
              breadcrumbs: {
                home: "หน้าแรก",
                insurance: "จัดการชุดแบบประกัน",
                insuranceDetail: "ชุดแบบประกันปี {{year}}",
                ipdTitle: "แบบประกัน IPD",
                opdTitle: "แบบประกัน OPD",
                dentalTitle: "แบบประกัน Dental",
              },
              success: "ทำรายการสำเร็จ",
              coverageLevel: "ระดับความคุ้มครอง",
              coverageLevelHelper:
                "*เพื่อให้ตรงตามวัตถุประสงค์ของสวัสดิการพนักงาน ถ้ามีการเลือกประกันให้ครอบครัวระบบจะคัดกรองเฉพาะแบบประกันครอบครัวที่ระดับต่ำกว่าหรือเท่ากับระดับความคุ้มครองที่เลือกให้ตัวพนักงานเองไปแสดงเท่านั้น",
              insuranceFor: "ประกันสำหรับ",
              insuranceType: "ประเภทประกัน",
              insuranceForOptions: {
                employee: "พนักงาน",
                family: "ครอบครัว",
              },
              addInsurance: "เพิ่มแบบประกัน",
            },
            InsurancePackage: {
              allPoint: "เหมา",
              add: "เพิ่มแพ็กเกจ",
              deleteModalTitle: "ลบ Package",
              deleteModalInfo1: "ข้อมูล Package",
              deleteModalInfo2: "จะถูกลบและไม่สามารถกู้คืนได้",
              close: "ปิดหน้าต่างนี้",
              confirmDelete: "ลบข้อมูล",
              save: "บันทึก",
              cancel: "ยกเลิก",
              back: "ย้อนกลับ",
              breadcrumbs: {
                home: "หน้าแรก",
                insurance: "จัดการชุดแบบประกัน",
                insuranceDetail: "ชุดแบบประกันปี {{year}}",
              },
              success: "ทำรายการสำเร็จ",
              newPackageHeader: "รายละเอียดแพ็กเกจ",
              packageNameTh: "ชื่อแพ็กเกจ (TH)",
              packageNameEn: "ชื่อแพ็กเกจ (EN)",
              remarkTh: "หมายเหตุ (TH)",
              remarkEn: "หมายเหตุ (EN)",

              insuranceName: "ชื่อแบบประกัน",
              insuranceNameTh: "ชื่อแบบประกัน (TH)",
              insuranceNameEn: "ชื่อแบบประกัน (EN)",
              insuranceType: "ประเภท",
              startDate: "วันเริ่มต้นคุ้มครอง",
              endDate: "วันสิ้นสุดคุ้มครอง",
              premium: "เบี้ยประกัน (บาท)",
              description: "รายละเอียด",
              saveAndNext: "บันทึกและถัดไป",
              saveSuccess: "บันทึกสำเร็จ",

              title: "จัดแพ็กเกจประกัน",
              infoText:
                "ถ้าบริษัทของลูกค้าให้พนักงานเลือกประกันเป็นแบบ flex สามารถข้ามขั้นตอนนี้ได้เลย",
              tableHeader: "ข้อมูลแพ็กเกจประกัน",
              name: "ชื่อแพ็กเกจ",
              remark: "หมายเหตุ",
              packagePricingType: "เบี้ยเต็ม",
              pricingType: {
                SUM_INSURANCES: "ราคารวมของแบบประกัน",
                ALL_REMAINING: "เหมา",
                SET_OWN_PRICE: "กำหนดราคาใหม่เอง",
              },
              SUM_INSURANCES: "ราคารวมของแบบประกัน",
              ALL_REMAINING: "เหมาด้วยคะแนนที่เหลือทั้งหมด",
              SET_OWN_PRICE: "กำหนดราคาใหม่เอง",
              insuranceForOptions: {
                employee: "พนักงาน",
                family: "ครอบครัว",
              },
              insuranceFor: "ประกันสำหรับ",
              priceType: "การคิดราคาแพ็กเกจ",
              coverageLevel: "ระดับความคุ้มครอง",
              coverageLevelHelper:
                "*เพื่อให้ตรงตามวัตถุประสงค์ของสวัสดิการพนักงาน ถ้ามีการเลือกประกันให้ครอบครัวระบบจะคัดกรองเฉพาะแบบประกันครอบครัวที่ระดับต่ำกว่าหรือเท่ากับระดับความคุ้มครองที่เลือกให้ตัวพนักงานเองไปแสดงเท่านั้น",
              packageFor: {
                employee: "ข้อมูลแพ็กเกจของพนักงาน",
                family: "ข้อมูลแพ็กเกจของครอบครัว",
              },
              level: "ระดับ",
              duplicateModalTitle: "คัดลอกแพ็กเกจ",
              duplicateModalContent: "ต้องการคัดลอกแพ็กเกจ",
              requiredPackage: "แพ็กเกจต้องมีข้อมูลประกันอย่างน้อย 1 ประเภท",
              cannotDeleteWhenHasBeenGroup:
                "ไม่สามารถลบได้เนื้องจากถูกนำไปจัดชุดข้อมูลในการลงทะเบียนปี {{year}}",
            },
            InsurancePreview: {
              warning:
                "หลังจากเผยแพร่แล้วถ้ามีการแก้ไขจะกระทบกับการทำงานของ HR กรุณาตรวจสอบข้อมูลให้ครบถ้วนก่อนเผยแพร่",
              title: "ตรวจสอบและเผยแพร่",
              subtitle1: "ช่วงเวลา",
              subtitle2: "ข้อมูลชุดแบบประกัน",
              sortByType: "แบ่งตามประเภท",
              startDate: "วันเริ่มต้นคุ้มครอง",
              endDate: "วันสิ้นสุดคุ้มครอง",
              tab1: "แบ่งตามประเภท",
              tab2: "แบ่งตามแพ็กเกจ",
              notFoundPackage: "ไม่พบข้อมูล Package",
              notFoundData: "ไม่พบข้อมูล",
              edit: "แก้ไข",
              cancel: "ยกเลิก",
              publish: "เผยแพร่",
              rePublish: "เผยแพร่อีกครั้ง",
              modalTitle: "เผยแพร่การจัดชุดแผนประกัน",
              insuranceName: "ชื่อแบบประกัน",
              insuranceRemark: "หมายเหตุ",
              packagePricingType: "ราคา Package",
              SUM_INSURANCES: "ราคารวมของแบบประกัน",
              ALL_REMAINING: "เหมาด้วยคะแนนที่เหลือทั้งหมด",
              rePublishModalTitleWarning: "เผยแพร่ชุดแบบประกันใหม่อีกครั้ง",
              rePublishModalWarning:
                "การเผยแพร่แบบประกันครั้งนี้ จะส่งผลให้ประกันทั้งหมดที่ HR จัดกลุ่มไว้หายไปและต้องทำการจัดกลุ่มใหมทั้งหมด",
              success: "ทำรายการสำเร็จ",
              insuranceTableHeader: "แบบประกัน {{name}} ที่เลือกได้",
              insuranceFor: {
                employee: "ข้อมูลชุดแบบประกันพนักงาน",
                family: "ข้อมูลชุดแบบประกันครอบครัว",
              },
              pricingType: "เบี้ยเต็ม",
              level: "ระดับ",
              pricingTypeText: {
                SUM_INSURANCES: "ราคารวมของแบบประกัน",
                ALL_REMAINING: "เหมา",
                SET_OWN_PRICE: "กำหนดราคาใหม่เอง",
              },
            },
          },
          roles: {
            index: {
              title: "บทบาท",
              home: "หน้าแรก",
              addButton: "เพิ่มบทบาท",
              list: "รายการบทบาท",
              deleteRole: "ข้อมูล บทบาท จะถูกลบและไม่สามารถกู้คืนได้",
              deletedRole: "ลบข้อมูล",
              description: "คำอธิบาย",
              amount: "จำนวนผู้ใช้",
              updatedAt: "แก้ไขล่าสุด",
              deleteRoleSuccess: "ลบข้อมูลสำเร็จ",
              alreadyInUse: "ไม่สามารถลบได้ มีผู้ดูแลระบบใช้งานอยู่",
              cancel: "ปิดหน้าต่างนี้",
              delete: "ลบข้อมูล",
            },
            new: {
              addRole: "เพิ่มบทบาท",
              role: "บทบาท",
              home: "หน้าแรก",
              title: "ข้อมูลทั่วไป",
              detail: "รายละเอียดบทบาท",
              scope: "ขอบเขตการใช้งานระบบ",
              roleTitle: "ชื่อบทบาท",
              description: "คำอธิบาย",
              sync: "รับแจ้งเตือนเมื่อการ Sync ไม่สำเร็จ",
              read: "ดูข้อมูล",
              edit: "แก้ไขข้อมูล",
              adminManage: "จัดการ Admin",
              permissionManage: "จัดการ Permission",
              enrollmentManage: "จัดการ Enrollment",
              reportManage: "จัดการ Report",
              insuranceManagement: "จัดการประกัน",
              settingManage: "จัดการ Setting",
              cancel: "ยกเลิก",
              save: "บันทึก",
              cancelEdit: "ยกเลิกการแก้ไขข้อมูล",
              cancelConfirm:
                "ข้อมูลปัจจุบันจะไม่ถูกบันทึก และไม่สามารถกู้คืนได้",
              close: "ปิดหน้าต่างนี้",
              canceledEdit: "ยกเลิกการแก้ไข",
            },
            edit: {
              indexTitle: "บทบาท",
              editRole: "แก้ไขบทบาท",
              cancelEdit: "ยกเลิกการแก้ไขข้อมูล",
              cancelConfirm:
                "ข้อมูลปัจจุบันจะไม่ถูกบันทึก และไม่สามารถกู้คืนได้",
              close: "ปิดหน้าต่างนี้",
              canceledEdit: "ยกเลิกการแก้ไข",
              detail: "รายละเอียดบทบาท",
              scope: "ขอบเขตการใช้งานระบบ",
              cancel: "ยกเลิก",
              save: "บันทึก",
              roleTitle: "ชื่อบทบาท",
              description: "คำอธิบาย",
            },
          },
          insurances: {
            index: {
              home: "หน้าแรก",
              title: "จัดการชุดแบบประกัน",
              addButton: "สร้างชุดแบบประกัน",
              list: "ชุดแบบประกัน",
              year: "ปีที่ใช้งาน",
              amount: "จำนวนแบบประกัน",
              updatedAt: "วันที่แก้ไขล่าสุด",
              status: "สถานะ",
              modalDescription:
                "โปรดระบุปีที่ใช้งาน (ปีที่ใช้งานต้องไม่ซ้ำกับที่มีอยู่แล้วในระบบ)",
              duplicateTitle: "คัดลอกชุดแบบประกัน",
              duplicateDescription:
                "ระบบจะคัดลอกชุดแบบประกัน <b>{{year}}</b> มาสร้างเป็นต้นแบบของชุดแบบประกันในชื่อ <b>{{secondYear}}</b> เพื่อให้แก้ไขและใช้งานต่อไป",
              cancel: "ยกเลิก",
              duplicateSuccess: "ทำรายการสำเร็จ",
              alreadyInUse: "ไม่สามารถลบได้ มีชุดแบบประกันปีนี้แล้ว",
              reset: "รีเซ็ต",
              filter: "กรอง",
              reEditing: "แก้ไขการจัดชุดประกันที่เผยแพร่แล้ว",
              reEditingInfo:
                "การแก้ไขจะทำให้ HR ไม่สามารถสร้าง enrollment ด้วยชุดประกันนี้ได้ จนกว่าจะมีการเผยแพร่ชุดประกันใหม่อีกครั้ง",
              edit: "แก้ไข",
              deleteTitle: "ลบชุดแบบประกัน",
              deleteSuccess: "ทำรายการสำเร็จ",
              deleteDescription: "ต้องการลบข้อมูลชุดแบบประกันปี {{year}}",
              state: {
                all: "ทั้งหมด",
                draft: "แบบร่าง",
                published: "เผยแพร่",
                publishing: "กำลังเผยแพร่",
                editing: "แก้ไข",
                active: "ใช้งาน",
                closed: "ปิด",
              },
            },
            package: {
              new: {
                save: "บันทึก",
                cancel: "ยกเลิก",
                name: "ชื่อ Package",
                ipd: "ประกัน IPD",
                opd: "ประกัน OPD",
                dental: "ประกัน Dental",
                remark: "หมายเหตุ",
              },
            },
          },
          settings: {
            index: {
              home: "หน้าแรก",
              settingMenu: "การตั้งค่าระบบ",
              title: "ตั้งค่าข้อมูลเกี่ยวกับ Belive",
              titleDetail:
                "รายละเอียดจะนำไปแสดงในหน้า ตั้งค่า -> เกี่ยวกับ Belive ของแอปพลิเคชั่น",
              logo: "โลโก้บริษัท",
              logoDetail: "แนะนำให้ใช้รูป .jpg ขนาดอย่างน้อย 100 x 100 px ",
              syncSetting: "ตั้งค่าการ Sync ข้อมูลพนักงาน",
              syncSettingDetail:
                "ข้อมูลพนักงานจะถูกใช้ในขั้นตอนการสร้าง Enrollment ของ HR",
              reportSetting: "ตั้งค่าการสร้างรายงานอัตโนมัติ",
              reportSettingDetail: "ใช้ในขั้นตอนการสร้างรายงานอัตโนมัติของระบบ",
              createReport: "สร้างรายงานทุก",
              addImage: "เพิ่มรูป",
              editImage: "แก้ไข",
              name: "ชื่อบริษัท",
              nameTh: "ชื่อบริษัท (Th)",
              nameEn: "ชื่อบริษัท (En)",
              description: "รายละเอียดบริษัท",
              descriptionTh: "รายละเอียดบริษัท (Th)",
              descriptionEn: "รายละเอียดบริษัท (En)",
              email: "อีเมล",
              phoneNumber: "เบอร์โทรศัพท์",
              cancel: "ยกเลิก",
              save: "บันทึก",
              saveSuccess: "ทำรายการสำเร็จ",
              syncEmData: "Sync ข้อมูลพนักงานทุก",
              cancelEdit: "ยกเลิกการแก้ไขข้อมูล",
              cancelConfirm:
                "ข้อมูลปัจจุบันจะไม่ถูกบันทึก และไม่สามารถกู้คืนได้",
              close: "ปิดหน้าต่างนี้",
              canceledEdit: "ยกเลิกการแก้ไข",
            },
          },
          reports: {
            index: {
              home: "หน้าแรก",
              reportMenu: "ออกรายงาน",
              columns: {
                year: "ปีที่ใช้งาน",
                insuranceCompanyName: "บริษัทประกัน",
                createReportDate: "วันที่สร้างรายงาน",
                outgoingCustomerAmount: "แจ้งออก",
                incomingCustomerAmount: "แจ้งเข้า",
                sendStatus: "ส่งแล้ว",
              },
              modal: {
                title: "ปรับสถานะรายงาน",
                description: "ปรับสถานะการส่งรายงานเป็น",
                statusSend: "ส่งแล้ว",
                statusNotSend: "ยังไม่ได้ส่ง",
                cancelButtonLabel: "ยกเลิก",
                okButtonLabel: "ยืนยัน",
              },
            },
          },
          marketplaceReports: {
            index: {
              home: "หน้าแรก",
              reportMenu: "ออกรายงาน Marketplace",
              columns: {
                year: "ปีที่ใช้งาน",
                insuranceCompanyName: "บริษัทประกัน",
                createReportDate: "วันที่สร้างรายงาน",
                outgoingCustomerAmount: "แจ้งออก",
                incomingCustomerAmount: "แจ้งเข้า",
                sendStatus: "ส่งแล้ว",
              },
              modal: {
                title: "ปรับสถานะรายงาน",
                description: "ปรับสถานะการส่งรายงานเป็น",
                statusSend: "ส่งแล้ว",
                statusNotSend: "ยังไม่ได้ส่ง",
                cancelButtonLabel: "ยกเลิก",
                okButtonLabel: "ยืนยัน",
              },
            },
          },
        },
        auth: {
          ResetPassword: {
            forgotPassword: "ลืมรหัสผ่าน",
            inputEmail: "กรอกอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบใหม่",
            send: "ส่ง",
            link1:
              "ลิงก์สำหรับสร้างรหัสผ่านใหม่ได้ถูกส่งไปที่อีเมล \n{{email}}\n",
            link2:
              "เรียบร้อยแล้ว \n กรุณาคลิกลิงก์จากอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบ",
          },
        },
      },
      components: {
        common: {
          languagesDropdown: {
            label: "ภาษา",
            lang: "ไทย",
          },
          PopMenu: {
            logout: "ออกจากระบบ",
            logoutInfo1: "คุณต้องการออกจากระบบใช่หรือไม่ หากต้องการกรุณากดปุ่ม",
            logoutInfo2: "ด้านล่างเพื่อทำการออกจากระบบ",
            confirmLogout: "ยืนยัน",
            cancel: "ยกเลิก",
            profile: "โปรไฟล์",
            avatar: "AD",
          },
          MaterialIcon: {
            notFoundIcon: "ไม่พบไอคอน",
          },
          UploadAvatar: {
            avatar: "AD",
          },
        },
        advance: {
          AddressList: {
            total: "ทั้งหมด {{amount}}",
            addressNo: "ที่อยู่ {{index}}",
          },
          FileUploadList: {
            total: "ทั้งหมด {{amount}}",
          },
        },
        SideBar: {
          appName: "My Benefit",
        },
        Table: {
          noData: "ไม่มีข้อมูล",
          noDataOnFilter:
            "ไม่พบข้อมูลที่ค้นหา กรุณาตรวจสอบค่าในตัวกรองอีกครั้ง",
          noInsuranceData: "ไม่มีข้อมูลแบบประกัน",
        },
      },
      routes: {
        menu: {
          adminMenu: "ผู้ดูแลระบบ",
          roleMenu: "บทบาท",
          insuranceMenu: "จัดการชุดแบบประกัน",
          settingMenu: "ตั้งค่าระบบ",
          menu: "เมนู",
          insurance: "จัดการชุดแบบประกัน",
          enrollment: "การลงทะเบียน",
          report: "ออกรายงาน",
          marketplaceReport: "ออกรายงาน Marketplace",
        },
      },
      yup: {
        error: {
          required: "ต้องไม่เว้นว่างเอาไว้",
          invalidEmailSyntax: "รูปแบบอีเมลไม่ถูกต้อง",
          typeNumber: "ต้องเป็นตัวเลข",
          GtZero: "ต้องเป็นตัวเลขมากกว่า 0",
          GteZero: "ต้องเป็นตัวเลขมากกว่าหรือเท่ากับ 0",
          GteOne: "ต้องเป็นตัวเลขมากกว่าหรือเท่ากับ 1",
          insuranceNameThDuplicate: "ชื่อแบบประกัน (TH) ซ้ำกับที่มีในระบบ",
          insuranceNameEnDuplicate: "ชื่อแบบประกัน (EN) ซ้ำกับที่มีในระบบ",
          packageNameThDuplicate: "ชื่อแพ็กเกจ (TH) ซ้ำกับที่มีในระบบ",
          packageNameEnDuplicate: "ชื่อแพ็กเกจ (EN) ซ้ำกับที่มีในระบบ",
        },
      },
      error: {
        required: "ต้องไม่เว้นว่างเอาไว้",
        saveError: "แบบประกันนี้ถูกเผยแพร่แล้ว",
        accept: "ตกลง",
        saveErrorChildren:
          "การลงทะเบียนนี้ถูกเผยแพร่ไปแล้ว กรุณากดปุ่ม (ตกลง) เพื่อ refresh และลองใหม่อีกครั้ง",
        notEmptyAtLeastOne: " ",
      },
    },
  },
};

export default th;
