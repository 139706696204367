import { GridColDef } from "@material-ui/data-grid";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import {
  Box,
  Button,
  Field,
  Form,
  Grid,
  Modal,
  Notification,
  Select,
  Table,
  TextField,
} from "components";
import Typography from "components/common/Typography";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";
import { PERMISSIONS } from "constants/enums/permissions";
import { IOptions } from "constants/interfaces/options";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
  withAuthorize,
} from "enhancers";
import { TFunction } from "i18next";
import { PageContent } from "layouts";
import { BreadcrumbsProps } from "layouts/PageContent";
import enrollmentStore from "stores/enrollmentStore";
import { AppColor } from "theme/app-color";
import { Yup, gql, homePath, paths } from "utils/helper";
import CreateEnrollmentModal from "./CreateEnrollmentModal";
import { ReactComponent as RedTrashIcon } from "assets/icon/red_trash.svg";
import { handleNavigateEnrollmentStep } from "./enrollmentRoutes";

interface EnrollmentComponentProps {
  t: TFunction;
  columns: GridColDef[];
  breadcrumbs: BreadcrumbsProps[];
  hasEditPermission: boolean;
  pageActions: any[];
  stateOptions: any[];
  tableData: any[];
  loading: boolean;
  visible: boolean;
  isFilter: boolean;

  handleClickFilter: () => void;
  handleClickResetFilter: () => void;
  handleClickCloseModal: () => void;
}

const EnrollmentComponent = (props: EnrollmentComponentProps) => (
  <PageContent
    title={props.t(".title")}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.hasEditPermission ? props.pageActions : []}
  >
    <Form>
      <Box width="100%" mb={-6}>
        <Typography variant="h4">{props.t(".title")}</Typography>
        <Grid container spacing={6} mt="24px" mb="24px" alignItems="center">
          <Grid item xs={12} sm={5}>
            <Field
              component={Select}
              name="state"
              type="text"
              label={props.t(".status")}
              options={props.stateOptions}
              fullWidth
              disableClearable
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Field
              component={TextField}
              name="year"
              type="number"
              label={props.t(".year")}
              fullWidth
              fast={false}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button
                mr="16px"
                style={{ border: "none" }}
                onClick={props.handleClickResetFilter}
                variant="type"
              >
                {props.t(".reset")}
              </Button>
              <Button variant="contained" onClick={props.handleClickFilter}>
                {props.t(".filter")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Table
          columns={props.columns}
          rows={props.tableData}
          loading={props.loading}
          onRowClick={({ row }: any) => {
            console.log(row);

            const { id, subState, state } = row;
            if (state === EnumEnrollmentState.publishing) {
              // do something
              Notification.notify(
                "Unable to check details. Please wait a moment and refresh your browser."
              );
            } else {
              handleNavigateEnrollmentStep(id, subState);
            }
          }}
          density="compact"
          autoHeight
          isFilter={props.isFilter}
          disableSelectionOnClick
          includeToolbar
        />
      </Box>
    </Form>
    <CreateEnrollmentModal
      visible={props.visible}
      onCancel={props.handleClickCloseModal}
    />
  </PageContent>
);

const API = {
  GET_ENROLLMENTS: gql`
    query GET_ENROLLMENTS($state: String!, $year: Float) {
      enrollments(input: { state: $state, year: $year }) {
        id
        year
        state
        subState
        updatedAt
        amount
        startDate
        endDate
      }
    }
  `,
  DELETE_ENROLLMENT: gql`
    mutation DELETE_ENROLLMENT($id: String!) {
      deleteEnrollment(id: $id)
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      state: "all",
      year: "",
    }),
  }),
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withAuthorize(),
  withTranslation({ prefix: "pages.main.enrollment.index" }),
  withHooks((props: any, hooks: any) => {
    const {
      useState,
      useMemo,
      useCallback,
      useQuery,
      useEffect,
      useMutation,
    } = hooks;
    const { values, resetForm, t, hasPermission } = props;

    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const { data: enrollments, refetch: refetchEnrollment, loading } = useQuery(
      API.GET_ENROLLMENTS,
      {
        variables: { state: "all", year: null },
        fetchPolicy: "network-only",
      }
    );

    const [deleteEnrollment] = useMutation(API.DELETE_ENROLLMENT, {
      onCompleted: async (data: any) => {
        await refetchEnrollment({
          state: values.state,
          year: values.year === "" ? null : values.year,
        });
      },
    });

    const hasEditPermission = useMemo(
      () => hasPermission([PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]),
      [hasPermission]
    );

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    const columns = useMemo((): GridColDef[] => {
      return [
        {
          width: 150,
          field: "year",
          headerName: t(".year") || "",
        },
        {
          width: 200,
          field: "startDate",
          headerName: t(".startDate") || "",
          type: "dateOnly",
        },
        {
          width: 200,
          field: "endDate",
          headerName: t(".endDate") || "",
          type: "dateOnly",
        },
        {
          width: 150,
          field: "amount",
          headerName: t(".amount") || "",
        },
        {
          width: 100,
          field: "stateDisplay",
          headerName: t(".state.name") || "",
        },
        {
          width: 150,
          field: "updatedAt",
          headerName: t(".updatedAt") || "",
          type: "dateOnly",
        },
        {
          width: 150,
          field: "actions",
          headerName: t(".updatedAt") || "",
          type: "dateOnly",
        },
        {
          width: 50,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ];
    }, [t]);

    const handleClickDelete = useCallback(
      (params: any) => {
        const { id, year } = params.row;
        Modal.open({
          title: t(".deleteTitle"),
          children: (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="body1" color={AppColor["Text/Secondary"]}>
                  {t(".deleteModalInfo1")} &nbsp;
                </Typography>
                <Typography variant="body2" color={AppColor["Text/Secondary"]}>
                  {`"${t(".enrollment", { year })}"`} &nbsp;
                </Typography>
                <Typography variant="body1" color={AppColor["Text/Secondary"]}>
                  {t(".deleteModalInfo2")}
                </Typography>
              </div>
            </>
          ),
          okButtonColor: AppColor["Other/Danger"],
          cancelButtonLabel: t(".closeDeleteModal"),
          okButtonLabel: t(".deleteTitle"),
          onOk: async ({ close }: any) => {
            console.log("DELETE");
            await deleteEnrollment({
              variables: { id },
            });
            close();
          },
        });
      },
      [t, deleteEnrollment]
    );

    const tableData = useMemo(
      () =>
        enrollments?.enrollments.map((enrollment: any) => ({
          ...enrollment,
          amount:
            enrollment.amount === 0
              ? `${enrollment.amount}`
              : enrollment.amount,
          // state: t(`.state.${enrollment.state}`),
          stateDisplay: t(`.state.${enrollment.state}`),
          actions:
            hasEditPermission && enrollment.state === EnumEnrollmentState.draft
              ? [
                  {
                    Icon: RedTrashIcon,
                    onClick: handleClickDelete,
                  },
                ]
              : [],
        })) || [],
      [enrollments, t, hasEditPermission, handleClickDelete]
    );

    const handleClickCreateEnrollment = useCallback(() => {
      setIsCreateModalVisible(true);
    }, []);

    const handleClickCloseModal = useCallback(() => {
      setIsCreateModalVisible(false);
    }, []);

    const pageActions = useMemo(
      () => [
        {
          children: t(".createEnrollment"),
          startIcon: <AddIcon />,
          onClick: () => handleClickCreateEnrollment(),
          color: "primary",
          permittedRoles: [PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT],
        },
      ],
      [t, handleClickCreateEnrollment]
    );

    const stateOptions = useMemo((): IOptions[] => {
      const options: IOptions[] = Object.keys(EnumEnrollmentState).map(
        (key) => ({
          label: t(`.state.${key}`),
          value: EnumEnrollmentState[key as keyof typeof EnumEnrollmentState],
        })
      );
      return [
        {
          label: t(".state.all"),
          value: "all",
        },
        ...options,
      ];
    }, [t]);

    const handleClickFilter = useCallback(async () => {
      await refetchEnrollment({
        state: values.state,
        year: values.year === "" ? null : values.year,
      });
      setIsFilter(true);
    }, [values, refetchEnrollment, setIsFilter]);

    const handleClickResetFilter = useCallback(async () => {
      resetForm();
      await refetchEnrollment({ state: "all", year: null });
      setIsFilter(false);
    }, [resetForm, refetchEnrollment, setIsFilter]);

    useEffect(() => {
      enrollmentStore.clearStore();
    }, []);

    return {
      columns,
      breadcrumbs,
      tableData,
      pageActions,
      hasEditPermission,
      stateOptions,
      visible: isCreateModalVisible,
      loading,
      isFilter,

      handleClickCloseModal,
      handleClickFilter,
      handleClickResetFilter,
    };
  })
);

export const EnrollmentPage = enhancer(EnrollmentComponent);
