const en = {
  translation: {
    client: {
      enhancers: {
        withPreventLeaveDirtyForm: {
          cancelEdit: "Cancel Edit",
          cancelConfirm:
            "Current data will not be saved. and cannot be restored",
          close: "Close Window",
          canceledEdit: "Canceled Edit",
        },
      },
      api: {
        modal401Title: "Login expired",
        modal401Children: "Login expired Please log in again.",
        modal401OkButtonLabel: "Login",
        modal403Title: "Error",
        modal403Children:
          "Your user role doesn't have access to this section. Please log in again.",
        modal403OkButtonLabel: "Login",
        modal500Title: "Error",
        modal500OkButtonLabel: "Ok",
      },
      pages: {
        main: {
          admins: {
            index: {
              title: "Admin",
              home: "Home",
              addButton: "Add admin",
              admins: "Admin",
              deleteButton: "Delete admin",
              columns: {
                fullName: "Full name",
                email: "Email",
                status: "Status",
                code: "Code",
                lastActiveAt: "Last active at",
                createdAt: "Created at",
                updatedAt: "Updated at",
                role: "Role",
                values: {
                  availableStatus: {
                    active: "active",
                    inactive: "inactive",
                  },
                },
              },
            },
            new: {
              home: "home",
              rootPath: "Admin",
              title: "Add admin",
              header: "Input admin email",
              link: "URL link would be sent to this email",
              send: "Send",
              cancel: "Cancel",
              email: "Email",
              role: "Role",
              notificationSuccess: "successful transaction",
            },
            edit: {
              title: "General Data",
              home: "Home",
              status: "Status",
              rootPath: "Admin",
              cancel: "Cancel",
              save: "Save",
              cantEditYourself:
                "Editing status and roles cannot be done by yourself, only an Admin who has permission to edit permission of Admin can do so, such as Super Admin.",
              firstName: "First Name",
              lastName: "Last Name",
              role: "Role",
              email: "Email",
              available: "Available",
            },
          },
          authentication: {
            LoginPage: {
              or: "or",
              title: "My Benefit",
              title1: "Fill email and receive OTP for sign-in",
              title2: "Fill email and receive OTP for sign-up",
              emailField: "Email",
              getOtp: "Request OTP",
              invalidEmail: "Email is incorrect",
              signInWithAzureAd: "Sign in with Azure AD",
              logoutAzureAd: "Sign out Azure AD",
            },
            VerifyOtp: {
              title: "Fill OTP for sign-in",
              otpInfo:
                "Fill OTP from {{currentEmail}} (OTP expire in {{otpExpireInMin}} minutes)",
              otp: "OTP",
              refCode: "ref:{{refCode}}",
              notReceiveOtp: "Didn't get OTP?",
              retryOtp: "Resend OTP again",
              canRetryIn: "you can retry in {{timeLeft}} seconds",
              login: "Login",
              changeEmail: "Change Email",
              home: "Return to home page",
              otpMustNumber: "OTP must be a number",
            },
          },
          enrollment: {
            index: {
              title: "Enrollment",
              status: "Status",
              year: "Year",
              reset: "Reset",
              filter: "Filter",
              home: "Home",
              startDate: "Start date",
              endDate: "End date",
              amount: "Attendee",
              state: {
                name: "Status",
                all: "All",
                draft: "Draft",
                published: "published",
                publishing: "publishing",
                pending: "Pending",
                active: "Active",
                closed: "Close",
              },
              updatedAt: "Updated at",
              createEnrollment: "Create Enrollment",
              deleteTitle: "Delete",
              deleteModalInfo1: "The",
              enrollment: "enrollment at {{year}}",
              deleteModalInfo2: "will be deleted and cannot be restored.",
              closeDeleteModal: "close",
            },
            CreateEnrollmentModal: {
              title: "Create Enrollment",
              description:
                "Please specify the year of use. (The year of use must be the same as the one already in the system.)",
              year: "year",
              cancel: "cancel",
              error: {
                title: "Something went wrong!",
                close: "close",
              },
            },
            detail: {
              title: "Enrollment {{year}}",
              step: "Step ({{step}}/{{of}})",
              draft: "draft",
              pending: "pending",
              published: "published",
              publishing: "publishing",
              active: "active",
              closed: "closed",
              home: "Home",
              enrollment: "Enrollment",
              state: {
                1: "Period Settings",
                2: "Attendee Validating",
                3: "Insurance Management",
                4: "Insurance Package Management",
                5: "Data Settings",
                6: "Validating & Publish",
              },
              pendingText:
                "If the insurance information is found to be incorrect Please press notify the insurance policy amendment and contact Belive to correct the insurance policy before proceeding.",
              pendingButton: "Edit Insurance",
              cancel: "Cancel",
              pendingModalText1:
                "This Enrollment can be locked out until the insurance information is corrected by Belive.",
              pendingModalText2:
                "* After pressing notification of editing Please provide details of corrections through Belive's contact methods.",
            },
            PeriodSetting: {
              enrollmentPeriodSetting: "Period Setting",
              updatedAt: "updated at: {{updatedAt}}",
              startDate: "Start date to choose insurance",
              endDate: "End date to choose insurance",
              existingAttendeeSelectionPeriodInDays:
                "Period of existing attendee to choose",
              incomingAttendeeSelectionPeriodInDays:
                "Period of incoming attendee to choose",
              existingAttendeeSelectionPeriodInDaysInfo:
                "From the start date, choose insurance.",
              incomingAttendeeSelectionPeriodInDaysInfo:
                "From the date of becoming an employee",
              saveAndNext: "Save & Next",
              save: "Save",
              saveSuccess: "Successful",
            },
            enrollmentAttendee: {
              title: "Checked Attendees",
              field: "Employee code / Firstname-Lastname",
              reset: "Reset",
              filter: "Filter",
              next: "Next",
              lastUpdate: "Last update: {{updatedAt}}",
              updateData: "Update Data",
              code: "Code",
              name: "Name",
              department: "Department",
              position: "Position",
              filterNotFound:
                "No search results were found. Please check the values in the filter again.",
              syncModal:
                "The system is updating information. Please do not close this screen until the update is complete.",
              noAttendee: "No Attendee",
              syncSuccess: "Sync Success",
              syncFail: "Sync Fail",
            },
            enrollmentInsurancePackage: {
              title: "Packages",
              name: "Package name",
              coverageLevel: "ระดับความคุ้มครอง No translation",
              insuranceName: "{{insuranceName}}",
              remark: "Remark",
              next: "Next",
              lastUpdate: "Last update: {{updatedAt}}",
              insuranceFor: {
                employee: "Employee insurance package information",
                family: "ข้อมูลแผนประกันของครอบครัว No translation",
              },
            },
            attendeeGrouping: {
              file: "file",
              detail: "Insurance details for the App",
              deletetitle: "Delete",
              deletetitle1:
                "Insurance details data displayed in the App will be deleted and cannot be recovered.",
              cancel: "close",
              title: "Grouping",
              info:
                "* Points entered in this field include the initial insurance value.",
              currentScore: "CurrentScore",
              unit: "points",
              type: "Type",
              saveAndNext: "Save And Next",
              save: "Save",
              ok: "ok",
              titleError: "Unable to save data",
              errorDetail1:
                "The total value of the default insurances cannot exceed the initial points.",
              errorDetail2:
                "Please edit the “Available points” or re-select the default insurances for the following employee groups.",
              saveSuccess: "Successful",
              custom: "Choose by yourself",
              package: "Choose from insurance packages",
              none: "Not open to choose",
              InsuranceList: {
                add: "Select Insurance",
                deleteInsuranceModalTitle: "Delete Insurance",
                deleteInsuranceModalInfo1: "Insurance data",
                deletePackageModalTitle: "Delete Package",
                deletePackageModalInfo1: "Package data",
                deleteModalInfo2: "will be deleted and cannot be restored.",
                close: "Close Window",
                confirmDelete: "Delete",
                insuranceName: "Insurance name",
                packageName: "Package name",
                remark: "Remark",
                deleteSuccess: "Delete success",
                modalInsuranceTitle: "Select insurance {{type}} to attendee",
                modalPackageTitle: "Select package to attendee",
                modalCancel: "Cancel",
                modalConfirm: "Select",
                delete: "Delete",
                insuranceTypeHeader: "{{type}} insurance",
                packageHeader: "Package",
                default: "Default",
              },
              InsuranceSelectorModal: {
                insuranceName: "Insurance name",
                packageName: "Package name",
                remark: "Remark",
              },
            },
            validate_and_published: {
              index: {
                title: "Validating & Publishing",
                period: "Period",
                edit: "Edit",
                warningMessage:
                  "Once published, this round of registration information will no longer be able to be modified (other than by extending the validity period). Please check all information before publishing registration.",
                year: "Year",
                startDate: "Start date",
                endDate: "End date",
                existingEnrollmentDaysLeft:
                  "Existing Employee Enrollment DayLeft",
                incomingEnrollmentDaysLeft:
                  "Incoming Employee Enrollment DayLeft",
                days: "Days",
                insuranceDetails: "InsuranceDetails",
                score: "Score",
                type: "Type",
                published: "published",
                publishing: "publishing",
                preview: "Preview",
                employees: "Employees",
                code: "Code",
                name: "Name",
                department: "Department",
                position: "Position",
                insuranceName: "Insurance name",
                remark: "Remark",
                default: "Default",
                employeeDetail: "Employees Detail",
                customTitle: "Optional {{nameTitle}} insurance",
                packageTitle: "Package {{nameTitle}}",
                publishEnrollment: "Published Enrollment",
                cancel: "Cancel",
                publishModalText:
                  "Once published, it will not be possible to edit the registration information for this round again.",
                publishModalText2:
                  "Please verify all information before publishing the registration.",
                errorTitle: "Error",
                submit: "Submit",
                errorModalText:
                  "This Enrollment insurance dataset has been modified.",
                errorModalText2:
                  "Please wait until the modification of the insurance package is complete. Then do the list again.",
                success: "Successfully",
                errorValidateText:
                  "Cannot be published Because the details of the employee group {{title}} are not complete.",
                custom: "Choose by yourself",
                package: "Choose from insurance packages",
                none: "Not open to choose",
                syncEmployeeErrorOnPublishedTitle: "Cannot Published",
                syncEmployeeErrorOnPublished:
                  "Unable to retrieve latest employee information. making it impossible to publish Please check the employee information file. or contact the system administrator",
                publishingModal:
                  "The system is publishing enrollment. Please do not close this screen until the update is complete.",
              },
            },
            InsurancePlanValidating: {
              title: "Insurance Management",
              name: "Insurance name",
              remark: "Remark",
              next: "Next",
              updatedAt: "Latest Update : {{date}}",
              coverageLevel: "ระดับ No translation",
              insuranceFor: {
                employee: "Employee insurance information",
                family: "ข้อมูลแบบประกันของครอบครัว No translation",
              },
            },
            enrollmentInsurance: {
              home: "Home",
              enrollment: "Enrollment",
              name: "Insurance name",
              insuranceType: "Insurance type",
              protectionStartDate: "Protection start date",
              protectionEndDate: "Protection end date",
              premium: "Permium (Baht)",
              remark: "Remark",
              description: "Description",
              back: "Back",
              isFamily: "ประกันสำหรับ No translation",
              coverageLevel: "ระดับคววามคุ้มครอง No translation",
              insuranceFor: {
                employee: "พนักงาน No translation",
                family: "ครอบครัว No translation",
              },
            },
          },
          insurance: {
            detail: {
              title: "Insurance {{year}}",
              step: "Step ({{step}}/{{of}})",
              draft: "draft",
              published: "published",
              publishing: "publishing",
              editing: "editing",
              insuranceDetailTitle: "Insurance Management",
              packageTitle: "Package Management",
              validateAndPublish: "Validating & Publish",
              breadcrumbs: {
                home: "Home",
                insurance: "Insurance Management",
              },
            },
            InsuranceDetail: {
              companyName: "Company Name",
              duration: "Duration",
              protectionStartDate: "Start Protection",
              protectionEndDate: "End Protection",
              insuranceData: "Insurance",
              saveAndNext: "Save & Next",
              save: "Save",
              saveSuccess: "Successful",
              add: "Add Insurance",
              insuranceFor: {
                employee: "Employee insurance information",
                family: "ข้อมูลแบบประกันของครอบครัว No translation",
              },
            },
            InsuranceList: {
              add: "Add Insurance",
              deleteModalTitle: "Delete Insurance",
              deleteModalInfo1: "Insurance data",
              deleteModalInfo2: "will be deleted and cannot be restored.",
              close: "close",
              confirmDelete: "delete",
              name: "Insurance Name",
              premium: "Full Premium",
              remark: "Remark",
              deleteSuccess: "Successfully",
              cannotDeleteWhenUsedInPackage:
                "Can't be deleted because it's used in Packages.",
              cannotDeleteWhenHasBeenGroup:
                "Cannot be deleted because it was used to organize data in the annual registration {{year}}",
              saveYourPackagesFirst: "Please save your package first",
              coverageLevel: "Coverage Level",
              duplicateModalTitle: "คัดลอกแบบประกัน No translation",
              duplicateModalContent: "ต้องการคัดลอกแบบประกัน No translation",
            },
            InsuranceForm: {
              name: "Insurance Name",
              info: "Insurance Detail",
              nameTh: "Insurance Name (TH)",
              nameEn: "Insurance Name (EN)",
              premium: "Premium (Baht)",
              remark: "Remark",
              remarkTh: "Remark (TH)",
              remarkEn: "Remark (EN)",
              save: "Save",
              cancel: "Cancel",
              back: "Back",
              breadcrumbs: {
                home: "Home",
                insurance: "Insurance Management",
                insuranceDetail: "Insurance At {{year}}",
                ipdTitle: "IPD Insurance",
                opdTitle: "OPD Insurance",
                dentalTitle: "Dental Insurance",
              },
              success: "Successfully",
              coverageLevel: "Coverage Level",
              coverageLevelHelper:
                "*เพื่อให้ตรงตามวัตถุประสงค์ของสวัสดิการพนักงาน ถ้ามีการเลือกประกันให้ครอบครัวระบบจะคัดกรองเฉพาะแบบประกันครอบครัวที่ระดับต่ำกว่าหรือเท่ากับระดับความคุ้มครองที่เลือกให้ตัวพนักงานเองไปแสดงเท่านั้น No translation",
              insuranceFor: "ประกันสำหรับ No translation",
              insuranceType: "Insurance type",
              insuranceForOptions: {
                employee: "พนักงาน No translation",
                family: "ครอบครัว No translation",
              },
              addInsurance: "Add Insurance",
            },
            InsurancePackage: {
              add: "Add Insurance Package",
              allPoint: "allPoint",
              deleteModalTitle: "Delete Package",
              deleteModalInfo1: "Package data",
              deleteModalInfo2: "will be deleted and cannot be restored.",
              close: "Close",
              confirmDelete: "Delete",
              save: "Save",
              cancel: "Cancel",
              breadcrumbs: {
                home: "Home",
                insurance: "Insurance Management",
                insuranceDetail: "Insurance At {{year}}",
              },
              success: "Successfully",
              newPackageHeader: "Package detail",
              packageNameTh: "Package name (TH)",
              packageNameEn: "Package name (EN)",
              remarkTh: "Remark (TH)",
              remarkEn: "Remark (EN)",
              insuranceName: "Insurance Package Name",
              insuranceNameTh: "Insurance Package Name (TH)",
              insuranceNameEn: "Insurance Package Name (EN)",
              insuranceType: "Type",
              startDate: "Protection Start Date",
              endDate: "Protection End Date",
              premium: "Premium (Baht)",
              description: "Description",
              saveAndNext: "Save & Next",
              saveSuccess: "Successful",

              title: "Package management",
              infoText:
                "If the client company allows employees to choose the same insurance, flex can skip this step.",
              tableHeader: "Package insurance",
              name: "Package name",
              remark: "Remark",
              packagePricingType: "Package Pricing Type",
              pricingType: {
                SUM_INSURANCES: "Sum of all package prices",
                ALL_REMAINING: "All remaining",
                SET_OWN_PRICE: "Set own price",
              },
              SUM_INSURANCES: "Sum of all package prices",
              ALL_REMAINING: "All remaining",
              SET_OWN_PRICE: "Set own price",
              insuranceForOptions: {
                employee: "พนักงาน No translation",
                family: "ครอบครัว No translation",
              },
              insuranceFor: "ประกันสำหรับ No translation",
              priceType: "การคิดราคาแพ็กเกจ No translation",
              coverageLevel: "ระดับความคุ้มครอง No translation",
              coverageLevelHelper:
                "*เพื่อให้ตรงตามวัตถุประสงค์ของสวัสดิการพนักงาน ถ้ามีการเลือกประกันให้ครอบครัวระบบจะคัดกรองเฉพาะแบบประกันครอบครัวที่ระดับต่ำกว่าหรือเท่ากับระดับความคุ้มครองที่เลือกให้ตัวพนักงานเองไปแสดงเท่านั้น No translation",
              packageFor: {
                employee: "Employee package information",
                family: "ข้อมูลแพ็กเกจของครอบครัว No translation",
              },
              level: "ระดับ No translation",
              duplicateModalTitle: "คัดลอกแพ็กเกจ No translation",
              duplicateModalContent: "ต้องการคัดลอกแพ็กเกจ No translation",
              requiredPackage:
                "The package must contain at least one type of insurance information",
              cannotDeleteWhenHasBeenGroup:
                "Cannot be deleted because it was used to organize data in the annual registration {{year}}",
            },
            insurancePackages: {
              ViewInsurance: {
                name: "Name",
                type: "Type",
                startDate: "StartDate",
                endDate: "EndDate",
                premium: "Premium (Baht)",
                remark: "Remark",
                description: "Description",
              },
            },
            InsurancePreview: {
              warning:
                "After publishing, if there is an amendment that will affect the work of HR, please check the information completely before publishing",
              title: "Validating & Publish",
              subtitle1: "Period",
              subtitle2: "Insurance",
              sortByType: "Sort by type",
              startDate: "Start Date",
              endDate: "End Date",
              tab1: "Sort by type",
              tab2: "Sort by package",
              notFoundPackage: "Not found package",
              notFoundData: "Data Not found.",
              edit: "Edit",
              cancel: "Cancel",
              publish: "Publish",
              rePublish: "Republish",
              modalTitle: "Publish insurance",
              insuranceName: "Insurance name",
              insuranceRemark: "Remark",
              packagePricingType: "Package Pricing Type",
              SUM_INSURANCES: "Sum of all package prices",
              ALL_REMAINING: "All remaining",
              rePublishModalTitleWarning: "Republish insurance",
              rePublishModalWarning:
                "Publication of this insurance This will result in the loss of all insurances grouped by HR and need to be regrouped.",
              success: "Successfully",
              insuranceTableHeader: "Optional {{name}} insurance",
              insuranceFor: {
                employee: "Employee Insurance Set Information",
                family: "ข้อมูลชุดแบบประกันครอบครัว No translation",
              },
              pricingType: "Full premium",
              level: "ระดับ No translation",
              pricingTypeText: {
                SUM_INSURANCES: "Sum of all package prices",
                ALL_REMAINING: "All remaining",
                SET_OWN_PRICE: "Set own price",
              },
            },
          },
          roles: {
            index: {
              title: "Role",
              home: "Home",
              addButton: "Add role",
              list: "Roles",
              deleteRole: "Role data will be deleted and cannot be restored",
              deletedRole: "Delete",
              description: "Description",
              amount: "Amount of users",
              updatedAt: "Last update",
              deleteRoleSuccess: "Delete Role Success",
              alreadyInUse: "Already in use",
              cancel: "Cancel",
              delete: "Delete",
            },
            new: {
              addRole: "Add role",
              role: "Role",
              title: "General Data",
              home: "Home",
              detail: "Role detail",
              scope: "Scope Management",
              roleTitle: "Title",
              description: "Description",
              sync: "Sync Notification",
              read: "Read",
              edit: "Edit",
              adminManage: "Admin Management",
              permissionManage: "Permission Management",
              enrollmentManage: "Enrollment Management",
              reportManage: "Report Management",
              insuranceManagement: "Insurance Management",
              settingManage: "Setting Management",
              cancel: "cancel",
              save: "Save",
              cancelEdit: "Cancel Edit",
              cancelConfirm:
                "Current data will not be saved. and cannot be restored",
              close: "Close Window",
              canceledEdit: "Canceled Edit",
            },
            edit: {
              indexTitle: "Role",
              editRole: "Edit role",
              cancelEdit: "Cancel Edit",
              cancelConfirm:
                "Current data will not be saved. and cannot be restored",
              close: "Close Window",
              canceledEdit: "Canceled Edit",
              detail: "Role detail",
              scope: "Scope Management",
              cancel: "cancel",
              save: "Save",
              roleTitle: "Title",
              description: "Description",
            },
          },
          insurances: {
            index: {
              home: "Home",
              title: "Insurance",
              addButton: "Add Insurance",
              list: "Insurances",
              year: "Years",
              amount: "Amount of Insurance",
              updatedAt: "Last update",
              status: "State",
              modalDescription:
                "Please specify the year. (Year must not be the same as already existing in the system)",
              duplicateTitle: "Duplicate Insurance",
              duplicateDescription:
                "The system will copy the <b>{{year}}</b> insurance package to create a prototype of the insurance package in the name <b>{{secondYear}}</b> for further modification and use.",
              cancel: "Cancel",
              duplicateSuccess: "Duplicate success",
              alreadyInUse: "Already in use",
              reset: "Reset",
              filter: "Filter",
              reEditing: "Modify the published insurance",
              reEditingInfo:
                "The modification will prevent HR from creating enrollments with this insurance until the new insurance is released again.",
              edit: "edit",
              deleteTitle: "Delete Insurance",
              deleteSuccess: "Delete success",
              deleteDescription: "Delete Insurance {{year}}",
              state: {
                all: "All",
                draft: "Draft",
                published: "published",
                publishing: "publishing",
                editing: "Editing",
                active: "Active",
                closed: "Close",
              },
            },
            package: {
              new: {
                save: "Save",
                cancel: "Cancel",
                name: "Package Name",
                ipd: "IPD",
                opd: "OPD",
                dental: "Dental",
                remark: "Remark",
              },
            },
          },
          settings: {
            index: {
              home: "Home",
              settingMenu: "Setting Menu",
              title: "Setting Info About Belive",
              titleDetail:
                "Details will be displayed in the Settings -> About Belive page of the application.",
              logo: "Logo",
              logoDetail:
                "Recommended to use a .jpg image with at least xxx x yyy px size.",
              syncSetting: "Sync settings for employee data.",
              syncSettingDetail:
                "Employee information will be used in HR's Enrollment creation process.",
              reportSetting: "Automatic report generate setting.",
              reportSettingDetail:
                "Use in the automatic report generate process of the system.",
              createReport: "Create Report Every",
              addImage: "Add Image",
              editImage: "Edit Image",
              name: "Company Name",
              nameTh: "Company Name (Th)",
              nameEn: "Company Name (En)",
              description: "Company Description",
              descriptionTh: "Company Description (Th)",
              descriptionEn: "Company Description (En)",
              email: "Email",
              phoneNumber: "Phone number",
              cancel: "Cancel",
              save: "Save",
              saveSuccess: "Save Success",
              syncEmData: "Sync Employees Data Every",
              cancelEdit: "Cancel Edit",
              cancelConfirm:
                "Current data will not be saved. and cannot be restored",
              close: "Close Window",
              canceledEdit: "Canceled Edit",
            },
          },
          reports: {
            index: {
              home: "Home",
              reportMenu: "Report Menu",
              columns: {
                year: "Year",
                insuranceCompanyName: "Insurance company",
                createReportDate: "Create date",
                outgoingCustomerAmount: "Outgoing",
                incomingCustomerAmount: "Incoming",
                sendStatus: "State",
              },
              modal: {
                title: "Change report state",
                description: "Change report state to",
                statusSend: "sent",
                statusNotSend: "unsent",
                cancelButtonLabel: "Cancel",
                okButtonLabel: "Confirm ",
              },
            },
          },
          marketplaceReports: {
            index: {
              home: "Home",
              reportMenu: "Report Menu",
              columns: {
                year: "Year",
                insuranceCompanyName: "Insurance company",
                createReportDate: "Create date",
                outgoingCustomerAmount: "Outgoing",
                incomingCustomerAmount: "Incoming",
                sendStatus: "State",
              },
              modal: {
                title: "Change report state",
                description: "Change report state to",
                statusSend: "sent",
                statusNotSend: "unsent",
                cancelButtonLabel: "Cancel",
                okButtonLabel: "Confirm ",
              },
            },
          },
        },
        auth: {
          ResetPassword: {
            forgotPassword: "Forgot Password",
            inputEmail: "Input email to create password for login",
            send: "send",
            link1: "URL link has already send to email \n{{email}}\n",
            link2: "Please click below link to create password for login",
          },
        },
      },
      components: {
        common: {
          languagesDropdown: {
            label: "language",
            lang: "English",
          },
          PopMenu: {
            logout: "Logout",
            logoutInfo1: "Do you want to logout? If you want, please press",
            logoutInfo2: "to logout.",
            confirmLogout: "Confirm",
            cancel: "Cancel",
            profile: "Profile",
            avatar: "AD",
          },
          MaterialIcon: {
            notFoundIcon: "Icon Not Found",
          },
          UploadAvatar: {
            avatar: "AD",
          },
        },
        advance: {
          AddressList: {
            total: "total {{amount}}",
            addressNo: "address {{index}}",
          },
          FileUploadList: {
            total: "total {{amount}}",
          },
        },
        SideBar: {
          appName: "My Benefit",
        },
        Table: {
          noData: "No Data",
          noDataOnFilter:
            "No search results were found. Please check the values in the filter again.",
          noInsuranceData: "ไม่มีข้อมูลแบบประกัน No translation",
        },
      },
      routes: {
        menu: {
          adminMenu: "Admin",
          roleMenu: "Role",
          insuranceMenu: "Insurance",
          settingMenu: "Setting",
          menu: "menu",
          insurance: "Insurance Management",
          enrollment: "Enrollment",
          report: "Export Report",
          marketplaceReport: "Export Marketplace Report",
        },
      },
      yup: {
        error: {
          required: "Field is required",
          invalidEmailSyntax: "Invalid email syntax",
          typeNumber: "Must be a number",
          GtZero: "Must be a number greater than zero",
          GteZero: "Must be a number greater than or equal zero",
          GteOne: "Must be a number greater than or equal one",
          insuranceNameThDuplicate:
            "The insurance name (TH) is the same as the one in the system",
          insuranceNameEnDuplicate:
            "The insurance name (EN) is the same as the one in the system",
          packageNameThDuplicate:
            "The package name (TH) is the same as the one in the system",
          packageNameEnDuplicate:
            "The package name (EN) is the same as the one in the system",
        },
      },
      error: {
        required: "Field is required",
        saveError: "This insurance form has been published",
        accept: "Ok",
        saveErrorChildren:
          "This registration has already been published. Please press the (OK) button to refresh and try again.",
        notEmptyAtLeastOne: " ",
      },
    },
  },
};

export default en;
